<template>
  <div class="bidding-hero">
    <div class="container">
      <div class="bidding-hero__top">
        <img
          class="bidding-hero__logo"
          src="@/assets/img/bidding/bidding-logo.svg"
          alt=""
        />
        <h2 class="bidding-hero__title">{{ $t("deals") }}</h2>
      </div>
      <a href="https://trade.magnum.kz/" target="_blank" class="btn">{{
        $t("webPortal")
      }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "BiddingHero",
};
</script>
